import styled from 'styled-components';
import {scaleDesktop} from 'styled/scaleDesktop';

interface Props {
  widthIndex?: number
  heightIndex?: number
  fontSizeIndex?: number
}

const widthWidgetGameLineIndex = 28;
const heightWidgetGameLineIndex = 15;
const lineHeightWidgetGameLineIndex = 3.5;
const fontSizeWidgetGameLineIndex = 10;

export const WidgetGameLineIndexLong = styled.span<Props>`
  box-sizing: border-box;
  display: inline-block;
  color: #fff;
  width: ${({widthIndex = widthWidgetGameLineIndex, theme}) => scaleDesktop(widthIndex, theme.horizontal)};
  height: ${({heightIndex = heightWidgetGameLineIndex, theme}) => scaleDesktop(heightIndex, theme.horizontal)};
  line-height: ${lineHeightWidgetGameLineIndex}vw;
  font-size: ${({fontSizeIndex = fontSizeWidgetGameLineIndex, theme}) => scaleDesktop(fontSizeIndex, theme.horizontal)};
  font-family: 'Oswald-Light';
  text-align: center;
`;
