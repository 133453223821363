import styled from 'styled-components';
import {scaleDesktop} from 'styled/scaleDesktop';

interface Props {
    paddingLeft?: number
    paddingRight?: number
    alignItems?: boolean
}

const widthWidgetTypeLine = 138;
const bottomWidgetTypeLine = 3.4;

export const WidgetTypeLine = styled.div<Props>`
    position: absolute;
    width: ${scaleDesktop(widthWidgetTypeLine)};
    left: 50%;
    bottom: ${scaleDesktop(bottomWidgetTypeLine)};
    transform: translate(-50%, 0);
    box-sizing: border-box;
    display: flex;
    align-items: ${props => props.alignItems ? 'normal' : 'center'};
    justify-content: space-between;
    z-index: 1;
`;
