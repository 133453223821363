import React, {ReactElement} from 'react';
import 'App.scss';
import {BrowserRouter, Routes, Route, Navigate} from 'react-router-dom';
import WidgetСustomizable from './widget/WidgetСustomizable';
import Widget from './widget/Widget';
import WidgetTwoTeamsСustomizable from './widget/WidgetTwoTeamsСustomizable';
import WidgetTwoTeamsHorizontal from './widget/WidgetTwoTeamsHorizontal';
import WidgetLong from './widget/WidgetLong';

process.env.NODE_ENV !== 'production' &&
console.log(`BRANCH = ${process.env.REACT_APP_BRANCH && `Branch name = ${process.env.REACT_APP_BRANCH}`}`);

const App = (): ReactElement => <div className="app-widget">
    <BrowserRouter>
        <Routes>
            <Route path="/" element={<Navigate to="/ru"/>}/>
            <Route path="/:locale" element={<Widget/>}/>
            <Route path="/:locale/pre-match" element={<WidgetTwoTeamsСustomizable nude={false} type={'prematch'}/>}/>
            <Route
                path="/:locale/:id/pre-match"
                element={<WidgetTwoTeamsСustomizable nude={false} type={'prematch'}/>}
            />
            <Route
                path="/:locale/:id/:game_id/pre-match"
                element={<WidgetTwoTeamsСustomizable nude={false} type={'prematch'}/>}
            />
            <Route path="/:locale/:id" element={<Widget/>}/>
            <Route path="/:locale/:id/:game_id" element={<Widget/>}/>
            <Route path="/:locale/nude" element={<WidgetСustomizable/>}/>
            <Route path="/:locale/:id/nude" element={<WidgetСustomizable/>}/>
            <Route path="/:locale/:id/:game_id/nude" element={<WidgetСustomizable/>}/>
            <Route path="/:locale/:id/vs" element={<WidgetTwoTeamsСustomizable nude={false} type={'vs'}/>}/>
            <Route path="/:locale/:id/:game_id/vs" element={<WidgetTwoTeamsСustomizable nude={false} type={'vs'}/>}/>
            <Route path="/:locale/vs" element={<WidgetTwoTeamsСustomizable nude={false} type={'vs'}/>}/>
            <Route path="/:locale/nude/vs" element={<WidgetTwoTeamsСustomizable nude={true} type={'vs'}/>}/>
            <Route path="/:locale/:id/nude/vs" element={<WidgetTwoTeamsСustomizable nude={true} type={'vs'}/>}/>
            <Route
                path="/:locale/:id/:game_id/nude/vs"
                element={<WidgetTwoTeamsСustomizable nude={true} type={'vs'}/>}
            />
            <Route path="/:locale/web_partner"
                element={<WidgetTwoTeamsHorizontal nude={false}/>}/>
            <Route
                path="/:locale/:id/web_partner"
                element={<WidgetTwoTeamsHorizontal nude={false}/>}
            />
            <Route
                path="/:locale/:id/:game_id/web_partner"
                element={<WidgetTwoTeamsHorizontal nude={false}/>}
            />
            <Route
                path="/:locale/:id/:game_id/nude/vs"
                element={<WidgetTwoTeamsСustomizable nude={true} type={'vs'}/>}
            />
            <Route path="/h3t"
                element={<WidgetLong/>}
            />
        </Routes>
    </BrowserRouter>
</div>;
export default App;
