import React, {ReactElement, useState, useEffect} from 'react';
import {useParams} from 'react-router';
import {Normalize} from 'components/Normalize/Normalize';
import {WidgetBodyLong} from 'components/WidgetBodyLong/WidgetBodyLong';
import {WidgetTop} from 'components/WidgetTop/WidgetTop';
import {WidgetTypeLine} from 'components/WidgetTypeLine/WidgetTypeLine';
import {WidgetGameLineIndexLong} from 'components/WidgetGame/WidgetGameLineIndexLong';
import i18n from 'I18n/i18n';

interface LineInterface {
    banner: number
    coef_1: number
    coef_2: number
    gamer_1: {
        nick: string
        photo: {
            src: string
        }
    }
    gamer_2: {
        nick: string
        photo: {
            src: string
        }
    }
}

const SECONDS = 10000;
const FIFTEEN_SECONDS = 15000;
const COUNT_OF_DECIMAL = 3;

const WidgetLong = (): ReactElement => {
    const params = useParams();

    if (params.locale && i18n.language !== params.locale.toString()) {
        i18n.changeLanguage(params.locale.toString());
    }

    const [lines, setLines] = useState([]);
    const [indexs, setIndexs] = useState(0);
    const url = `${process.env.REACT_APP_API_URL as string}/api/v2/banner?game_id=57`;

    useEffect(() => {
        const interval = setInterval(() => {
            setIndexs(index => index >= lines.length - 1 ? 0 : index + 1);
        }, SECONDS);

        return () => clearInterval(interval);
    }, [lines]);

    useEffect(() => {
        const interval = setInterval(() => {
            fetch(url, {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json'
                },
                method: 'GET'
            })
                .then(response => response.json())
                .then(data => {
                    if (JSON.stringify(lines) !== JSON.stringify(data)) {
                        setLines(data);
                    }
                });
        }, FIFTEEN_SECONDS);

        return () => clearInterval(interval);
    }, [lines]);
    const line = lines[indexs] as LineInterface;
    return <>
        <Normalize/>
        <WidgetBodyLong>
            <WidgetTop heightFooter={0}>
                {line
                    ? <WidgetTypeLine>
                        <WidgetGameLineIndexLong>
                            {Number(line.banner === 1 ? line.coef_1 : line.coef_2).toFixed(COUNT_OF_DECIMAL)}
                        </WidgetGameLineIndexLong>
                        <WidgetGameLineIndexLong>
                            {Number(line.banner === 1 ? line.coef_2 : line.coef_1).toFixed(COUNT_OF_DECIMAL)}
                        </WidgetGameLineIndexLong>
                    </WidgetTypeLine>
                    : <WidgetTypeLine>
                        <WidgetGameLineIndexLong/>
                        <WidgetGameLineIndexLong/>
                    </WidgetTypeLine>}
            </WidgetTop>
        </WidgetBodyLong>
    </>;
};

export default WidgetLong;
